import React from 'react'
import styled from 'styled-components'

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #cbcbcb;

  caption {
    color: #000;
    font: italic 85%/1 arial, sans-serif;
    padding: 1em 0;
    text-align: center;
  }

  td,
  th {
    border-left: 1px solid #cbcbcb;
    border-width: 0 0 0 1px;
    font-size: inherit;
    margin: 0;
    overflow: visible;
    padding: 0.4em 0.8em;

    @media only screen and (max-width: 480px) {
      padding: 0.4em;
    }
  }

  tr:nth-child(even) {
    background-color: #ebebeb;
  }

  td {
    background-color: transparent;
  }

  thead {
    background-color: #17b978;
    color: #fff;
    text-align: left;
    vertical-align: bottom;
  }

  tr th {
    font-weight: 500;
  }
`

const PureTable = (props) => {
  return <Table>{props.children}</Table>
}

export default PureTable
