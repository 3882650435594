import React from 'react'
import { Helmet } from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import PureTable from '../components/PureTable'
import styled from 'styled-components'

import SEO from '../components/SEO'

import { graphql } from 'gatsby'

import orderBy from 'lodash/orderBy'

import { dataOrganizer } from '../utils/dataOrganizer'

const Subtitle = styled.h2`
  font-size: 1.5em;
  font-weight: 600;
  margin: 2rem 0 1rem;
  text-transform: none;
`

const CashbacksPage = ({ data, theme }) => {
  const { groupedBySlug } = dataOrganizer(data)

  const cashbackTable = (retailer) => {
    const orderedData = orderBy(
      groupedBySlug[retailer],
      'cashback_number',
      'desc'
    )

    orderedData.map((edge, index) => (
      <tr key={edge.cashback_id}>
        <td>{index + 1}</td>
        <td key={index}>
          <a href={edge.url}>{edge.site}</a>
        </td>
        <td>{edge.cashback}</td>
      </tr>
    ))
  }

  const tableByRetailer = Object.keys(groupedBySlug).map((retailer) => {
    return (
      <section id={retailer} key={retailer.id}>
        <Subtitle>{retailer}</Subtitle>
        <PureTable>
          <thead>
            <tr>
              <th>#</th>
              <th>Site</th>
              <th>Cashback</th>
            </tr>
          </thead>
          <tbody>{cashbackTable(retailer)}</tbody>
        </PureTable>
      </section>
    )
  })

  const postNode = {
    title: `Todas as Lojas - Cashback Ranking`,
  }

  return (
    <Layout>
      <Helmet>
        <title lang={config.language}>Todas as Lojas - Cashback Ranking</title>

        <meta charset="utf-8"></meta>
        <link rel="canonical" href="https://www.cashbackranking.com.br/cashbacks/" />
        <meta
          name="description"
          content="Todas as lojas - Compare cashback no Cashback Ranking: As melhores ofertas da internet! - Extensão Cashback Ranking"
        />
        <meta httpEquiv="content-language" content="pt-br" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1"></meta>
        <meta name="HandheldFriendly" content="True"></meta>
        <meta name="MobileOptimized" content="320"></meta>
      </Helmet>

      <SEO postNode={postNode} pagePath="cashbacks" customTitle />

      <Container>
        <PageTitle>Todas as Lojas</PageTitle>
        <p>
          Veja quanto de cashback (dinheiro de volta) você recebe nas principais
          plataformas de cashback do Brasil.
        </p>
        {tableByRetailer}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    allCashback {
      edges {
        node {
          id
          cashback_type
          cashback_number
          cashback_id
          cashback
          name
          short_url
          site
          slug
          store_icon_url
          store_url
          updated_at
          url
        }
      }
    }
  }
`

export default CashbacksPage
