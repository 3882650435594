import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import uniqWith from 'lodash/uniqWith'

export const orderedByCashbackNumber = (data) => {
  return filter(
    uniqWith(
      orderBy(data, ['cashback_type', 'cashback_number'], ['asc', 'desc']),
      (value, other) => {
        return value.cashback_id === other.cashback_id // is equal
      }
    ),
    (obj) => obj.cashback_number !== 0 && obj.cashback !== ''
  )
}

export const dataOrganizer = (data) => {
  const nodeCashbackNotNull = ({ node }) =>
    node.cashback_number && node.cashback_type

  const cleanedData = data.allCashback.edges.filter(nodeCashbackNotNull)

  const itemsLastUpdateDate = new Date(cleanedData[0].node.last_update)
  const formattedLastUpdate = itemsLastUpdateDate.toLocaleString()

  const itemsWithCashbackToFloat = cleanedData.map(({ node }, index) => ({
    ...node,
    cashback_number: parseFloat(node.cashback_number),
    cashback_type: parseFloat(node.cashback_type),
  }))

  const itemsWithCashbackToFloatFiltered = filter(
    itemsWithCashbackToFloat,
    (obj) => obj.cashback_number !== 0 && obj.cashback !== ''
  )

  const groupedBySlug = groupBy(itemsWithCashbackToFloatFiltered, 'slug')
  const groupedByName = groupBy(itemsWithCashbackToFloatFiltered, 'name')

  return { groupedBySlug, groupedByName, formattedLastUpdate }
}
